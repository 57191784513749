import React from 'react'
import CaseStudies from '../../components/case-studies'

const AkshatSiddharth = () => {
    return (
        <CaseStudies 
        title="Akshat Siddharth | Case Study | Crampete"
        imgSrc = "https://crampete-staticfiles.s3.ap-south-1.amazonaws.com/images/case-studies/student-case-study-akshat.jpg"
        altText = "Akshat Siddharth"
        />
    )
}

export default AkshatSiddharth;
