import React from 'react'
import Layout from './layout'

const CaseStudies = ({title, imgSrc, altText}) => {
    return (
        <Layout title={title} >
            <div className="container">
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div className="single-case-study-wrapper">
                            <img src= {imgSrc} alt={altText} />
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
            </div>
        </Layout>
    )
}

export default CaseStudies;
